var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "rating-poll",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c("ResizableText", {
        staticClass: "rating-poll__text",
        attrs: { message: _vm.mission.instructions },
      }),
      !_vm.isPresenterUser
        ? _c(
            "SlideFadeTransition",
            [
              _vm.isPlayUI
                ? _c("StarRating", {
                    staticClass: "rating-poll__rate",
                    attrs: {
                      "border-color": "#fff",
                      "active-color": "#fff",
                      "inactive-color": "#1f1f1f",
                      rating: _vm.givenAnswer || 0,
                      padding: 4,
                      increment: 0.5,
                      "star-size": 24,
                      "border-width": 2,
                      "show-rating": false,
                      "read-only": _vm.isHost || !!_vm.givenAnswer,
                    },
                    on: {
                      "rating-selected": function ($event) {
                        _vm.answer = $event
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.isPresenterUser
        ? _c(
            "SlideFadeTransition",
            [
              _vm.isPlayUI
                ? _c(
                    "RtbButton",
                    {
                      staticClass:
                        "font-bold capitalize text-lg px-4 mx-auto mb-3",
                      attrs: { disabled: _vm.isHost || !!_vm.givenAnswer },
                      on: { click: _vm.onSubmit },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.givenAnswer ? "Submitted" : "Submit") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }