<template>
  <v-layout column justify-center fill-height class="rating-poll">
    <ResizableText :message="mission.instructions" class="rating-poll__text" />

    <SlideFadeTransition v-if="!isPresenterUser">
      <StarRating
        v-if="isPlayUI"
        class="rating-poll__rate"
        border-color="#fff"
        active-color="#fff"
        inactive-color="#1f1f1f"
        :rating="givenAnswer || 0"
        :padding="4"
        :increment="0.5"
        :star-size="24"
        :border-width="2"
        :show-rating="false"
        :read-only="isHost || !!givenAnswer"
        @rating-selected="answer = $event"
      />
    </SlideFadeTransition>

    <SlideFadeTransition v-if="!isPresenterUser">
      <RtbButton
        v-if="isPlayUI"
        class="font-bold capitalize text-lg px-4 mx-auto mb-3"
        :disabled="isHost || !!givenAnswer"
        @click="onSubmit"
      >
        {{ givenAnswer ? "Submitted" : "Submit" }}
      </RtbButton>
    </SlideFadeTransition>
  </v-layout>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import StarRating from "vue-star-rating"
import { get } from "lodash"

import Ellipsis from "@/components/GroupTeams/Common/Games/GameCardParts/Ellipsis"
import SlideFadeTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import { RtbButton } from "@/components/ui"

import { GameMixin } from "@/mixins"

import User from "@shared/User"
import Mode from "@shared/enums/Mode"

export default Vue.extend({
  name: "RatingPoll",
  components: {
    Ellipsis,
    RtbButton,
    StarRating,
    ResizableText,
    MissionStatus,
    SlideFadeTransition
  },
  mixins: [GameMixin],
  props: {
    mission: Object,
    mode: {
      type: String,
      default: undefined
    }
  },

  computed: {
    ...mapGetters("group", ["isViewerHostLike"]),
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapGetters([
      "missionCompleted",
      "isScribe",
      "missionPlaysArray",
      "plays"
    ]),
    isPresenterUser() {
      return User.isPresenter(this.user)
    },
    isWaiting() {
      return !this.isScribe && !this.missionCompleted
    },
    givenAnswer() {
      const userPlay = this.missionPlaysArray.find(
        ({ userID }) => userID === this.user.id
      )
      return get(userPlay, "answer[0]", 0)
    },
    isPlayUI() {
      return this.mode === Mode.Play
    }
  },

  methods: {
    async onSubmit() {
      await this.checkAnswer()
    }
  }
})
</script>

<style scoped lang="scss">
.rating-poll {
  overflow: hidden;

  &__text {
    flex: 3;
    width: 100%;
  }

  &__rate {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    flex: 1;
  }
}
</style>
